<template>
  <div
    class="sales-statistic-wrapper page"
  >
    <alert
      type="warning"
      title="Внимание"
      description="Здесь отображается статистика за предыдущие 3 месяца!"
    />

    <el-card
      class="box-card box-control"
    >
      <div class="box-control-title">График продаж</div>

      <line-chart
        :data="getDataAllSales"
      />
    </el-card>

    <el-card
      class="box-card box-control"
    >
      <div class="box-control-title">Сумма продаж по дням недели</div>

      <bar-chart
        :data="getDataAllSalesToWeekPrice"
      />
    </el-card>

    <el-card
      class="box-card box-control"
    >
      <div class="box-control-title">Топ 15 товаров по кол. продаж</div>

      <pie-chart
        :data="getDataAllSalesToProducts"
      />
    </el-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Alert from '@/components/alert'
import LineChart from '@/components/lineChart'
import BarChart from '@/components/barChart'
import PieChart from '@/components/pieChart'

export default {
  name: 'salesStatistic',
  components: {
    Alert,
    LineChart,
    BarChart,
    PieChart
  },
  computed: {
    ...mapGetters('workingShift', ['getRawData', 'getIsDataLoaded']),
    ...mapGetters('statistic', [
      'getDataAllSales',
      'getDataAllSalesToWeekPrice',
      'getDataAllSalesToProducts'
    ])
  },
  async created () {
    await this.loadData()
  },
  methods: {
    ...mapActions('user', { loadDataUser: 'loadData' }),
    ...mapActions('settings', { loadDataSettings: 'loadData' }),
    ...mapActions('workingShift', { loadDataWorkingShift: 'loadData' }),
    ...mapActions('statistic', { loadDataStatistic: 'loadData' }),
    async loadData () {
      // TODO: Подумать над оптимизацией загрузки данных НА ВСЕХ стр.
      await this.$bus.emit('start-loader')
      await this.loadDataUser()
      await this.loadDataSettings()
      await this.loadDataWorkingShift()
      await this.loadDataStatistic()
      await this.$nextTick()
      await this.$bus.emit('stop-loader')
    },
  }
}
</script>

<style lang="scss" src="./style.scss" />